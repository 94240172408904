/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAcVV0WqkR81JqUy1feRjn7rcpcXY1-4HY",
  "appId": "1:186217563528:web:2709f2a28f9440eb47263d",
  "authDomain": "schooldog-i-chickamauga-ga.firebaseapp.com",
  "measurementId": "G-8DETS9W0NK",
  "messagingSenderId": "186217563528",
  "project": "schooldog-i-chickamauga-ga",
  "projectId": "schooldog-i-chickamauga-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-chickamauga-ga.appspot.com"
}
